<template>
  <div class="wheel-design-overview">
    <base-rimconfig-filter-bar
      @reset="onResetFilter"
    >
      <template #select-group>
        <div class="filter-list">
          <div class="wheel-design-select-filter-list">
            <base-select-filter-dropdown
              v-for="(selectField, index) in selectFilterFields"
              :ref="`${selectField.key}FilterDropdown`"
              :key="`select${selectField.key}${index}`"
              v-model="inputSelectFilter[selectField.key]"
              :is-loading="isLoadingFilter"
              :option-items="selectFilterOptions[selectField.key]"
              :placeholder="$t(selectField.placeholder)"
              :single="selectField.single"
              :select-label="inputSelectFilter[selectField.key].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
              @input="onInputSelectFilter(selectField, `${selectField.key}FilterDropdown`)"
            />
          </div>
          <div class="wheel-design-checkbox-list">
            <base-checkbox
              v-for="(property, index) in propertiesFilterFields"
              :key="`${property.key}${index}`"
              v-model="inputPropertyFilter[property.key]"
              :label-text="$t(property.label)"
              class="checkbox-item"
              @input="onInputSelectFilter"
            />
          </div>
        </div>
      </template>
    </base-rimconfig-filter-bar>
    <v-container>
      <div
        v-if="isLoadingWheelDesign"
        class="loading__wrapper"
      >
        <div class="loader">
          <v-progress-circular
            indeterminate
            color="accent"
          />
        </div>
      </div>
      <div
        v-else-if="getGroupedWheelsByDesignAndColor.length === 0"
        class="no-result__wrapper"
      >
        {{ $t('langkey.no-result') }}
      </div>
      <div
        v-else
        class="wheel-design-card__wrapper"
      >
        <wheel-design-card
          v-for="(item, index) in getGroupedWheelsByDesignAndColor"
          :key="`${item.man}${index}`"
          :wheel="item"
          @on-click-wheel-design="onClickWheelDesign(item)"
        />
      </div>
    </v-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/wheel-design-overview.scss";
</style>

<script>
import BaseRimconfigFilterBar from '@/components/BaseRimconfigFilterBar';
import WheelDesignCard from '@/components/WheelDesignCard';
import { mapState, mapGetters } from 'vuex';
import {
  selectFilterFields,
  propertiesFilterFields,
} from '@/pages/rims-config/wheel-design/overview/configs';

export default {
  components: {
    BaseRimconfigFilterBar,
    WheelDesignCard,
  },
  data() {
    return {
      inputSelectFilter: {},
      inputPropertyFilter: {},
      isLoadingFilter: false,
      isLoadingWheelDesign: false,
    };
  },
  computed: {
    ...mapState({
      wheelDesigns: (state) => state.rimconfigBase.wheelDesigns,
      wheelsByQuery: (state) => state.rimconfigBase.wheelsByQuery,
    }),
    ...mapGetters('rimconfigBase', [
      'getGroupedWheelsByDesignAndColor',
      'getWheelsSelectFilterOptions',
    ]),
    getBrand() {
      return this.$route.query.brand;
    },
    getSearch() {
      return this.$route.query.search;
    },
    propertiesFilterFields() {
      return propertiesFilterFields;
    },
    selectFilterFields() {
      return selectFilterFields;
    },
    selectFilterOptions() {
      return this.getWheelsSelectFilterOptions(this.selectFilterFields);
    },
  },
  created() {
    if (!(this.getBrand || this.getSearch)) {
      this.toWheelDesign();
    }

    if (this.getBrand) {
      this.isLoadingWheelDesign = true;
      this.$store.dispatch('rimconfigBase/getWheelDesigns', {
        manufacturers: this.getBrand,
      })
        .finally(() => {
          this.isLoadingWheelDesign = false;
        });
    } else if (this.getSearch) {
      this.isLoadingWheelDesign = true;
      this.$store.dispatch('rimconfigBase/getWheelsByQuery', {
        query: this.getSearch,
      })
        .then((res) => {
          const len = res.data.length;

          if (len === 0) {
            this.$store.dispatch('dialog/setShowErrorDialog', {
              status: true,
              title: this.$t('langkey.wheels-not-found'),
              message: this.$t('langkey.please-try-other-parameters'),
            });
            this.toWheelDesign();
          } else {
            const promise1 = this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              wheelTags: res.data.join(),
            });
            this.isLoadingFilter = true;
            const promise2 = this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
              wheelTags: res.data.join(),
            });
            Promise.all([promise1, promise2])
              .catch(() => {
                this.$store.dispatch('dialog/setShowErrorDialog', {
                  status: true,
                  title: this.$t('langkey.wheels-not-found'),
                  message: this.$t('langkey.please-try-other-parameters'),
                });
                this.toWheelDesign();
              })
              .finally(() => {
                this.isLoadingFilter = false;
              });
          }
        })
        .finally(() => {
          this.isLoadingWheelDesign = false;
        });
    }

    this.setInitInputSelectFilter();
    this.setInitInputPropertyFilter();

    if (this.getBrand) {
      this.isLoadingFilter = true;
      this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
        manufacturers: this.getBrand,
      }).finally(() => {
        this.isLoadingFilter = false;
      });
    }
  },
  methods: {
    onClickWheelDesign(item) {
      let queryObj = {};
      if (this.getBrand) {
        queryObj = {
          brand: this.getBrand,
        };
      } else if (this.getSearch) {
        queryObj = {
          search: this.getSearch,
          brand: item.manufacturer,
        };
      }
      let colorQueryObj = item.color ? {color: item.color} : {};
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-wheelDesign_design-details',
          query: {
            ...queryObj,
            design: item.design,
            ...colorQueryObj,
          },
        }),
      );
    },
    setInitInputSelectFilter() {
      selectFilterFields.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.key]: [],
        };
      });
    },
    setInitInputPropertyFilter() {
      propertiesFilterFields.forEach((item) => {
        this.inputPropertyFilter = {
          ...this.inputPropertyFilter,
          [item.key]: false,
        };
      });
    },
    onResetFilter() {
      this.setInitInputPropertyFilter();
      this.setInitInputSelectFilter();

      const selectFilterPayload = this.getFilterPayload(this.inputSelectFilter);
      let propertyFilterPayload = {};
      Object.keys(this.inputPropertyFilter).forEach((propertyKey) => {
        if (this.inputPropertyFilter[propertyKey]) {
          propertyFilterPayload = {
            ...propertyFilterPayload,
            [propertyKey]: true,
          };
        }
      });
      if (this.getBrand) {
        this.isLoadingFilter = true;
        this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
          ...selectFilterPayload,
          ...propertyFilterPayload,
          manufacturers: this.getBrand,
        })
          .then(() => {
            this.isLoadingWheelDesign = true;
            this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              ...selectFilterPayload,
              ...propertyFilterPayload,
              manufacturers: this.getBrand,
            })
              .finally(() => {
                this.isLoadingWheelDesign = false;
              });
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoadingFilter = false;
          });
      } else if (this.getSearch) {
        this.isLoadingFilter = true;
        this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
          ...selectFilterPayload,
          ...propertyFilterPayload,
          wheelTags: this.wheelsByQuery.join(),
        })
          .then(() => {
            this.isLoadingWheelDesign = true;
            this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              ...selectFilterPayload,
              ...propertyFilterPayload,
              wheelTags: this.wheelsByQuery.join(),
            })
              .finally(() => {
                this.isLoadingWheelDesign = false;
              });
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoadingFilter = false;
          });
      }
    },
    onInputSelectFilter(selectField, refKey) {
      const selectFilterPayload = this.getFilterPayload(this.inputSelectFilter);
      if (refKey && selectField.single) {
        this.$refs[refKey][0].hideDropdown();
      }
      let propertyFilterPayload = {};
      Object.keys(this.inputPropertyFilter).forEach((propertyKey) => {
        if (this.inputPropertyFilter[propertyKey]) {
          propertyFilterPayload = {
            ...propertyFilterPayload,
            [propertyKey]: true,
          };
        }
      });
      if (this.getBrand) {
        this.isLoadingFilter = true;
        this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
          ...selectFilterPayload,
          ...propertyFilterPayload,
          manufacturers: this.getBrand,
        })
          .then(() => {
            this.isLoadingWheelDesign = true;
            this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              ...selectFilterPayload,
              ...propertyFilterPayload,
              manufacturers: this.getBrand,
            })
              .finally(() => {
                this.isLoadingWheelDesign = false;
              });
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoadingFilter = false;
          });
      } else if (this.getSearch) {
        this.isLoadingFilter = true;
        this.$store.dispatch('rimconfigBase/getWheelsConcatenated', {
          ...selectFilterPayload,
          ...propertyFilterPayload,
          wheelTags: this.wheelsByQuery.join(),
        })
          .then(() => {
            this.isLoadingWheelDesign = true;
            this.$store.dispatch('rimconfigBase/getWheelDesigns', {
              ...selectFilterPayload,
              ...propertyFilterPayload,
              wheelTags: this.wheelsByQuery.join(),
            })
              .finally(() => {
                this.isLoadingWheelDesign = false;
              });
          })
          .catch(() => {
          })
          .finally(() => {
            this.isLoadingFilter = false;
          });
      }
    },
    getFilterPayload(filters) {
      let payload = {};
      Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key]) && filters[key].length > 0) {
          payload = {
            ...payload,
            [`${key}s`]: filters[key].join(),
          };
        } else if (!Array.isArray(filters[key]) && filters[key]) {
          payload = {
            ...payload,
            [`${key}s`]: filters[key],
          };
        }
      });
      return payload;
    },
    toWheelDesign() {
      this.$router.push(
        this.$i18nRoute({
          name: 'rims-config-wheelDesign',
        }),
      );
    },
  },
};
</script>