<template>
  <div
    class="wheel-design-card"
    @click="onClickWheelDesign"
  >
    <div class="wheel-image-wrapper">
      <img
        v-lazy="getWheelImage(wheel.picturePerspective)"
        class="wheel-image"
      >
      <icon-snow
        v-if="wheel.winter"
        class="season__icon"
      />
      <icon-three-sixty
        v-if="isWheelThreesixty"
        class="three-sixty__icon"
      />
    </div>
    <div class="wheel-desc-wrapper">
      <span class="design">{{ wheel.design }}</span>
      <span class="color">{{ wheel.color }}</span>
      <img
        :src="`https://cdn.jfnet.de/wheellogos/${wheel.manufacturer.replace(/\s/g, '')}/200x80-cc-xxxxxx.png`"
        class="brand-image"
      >
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/wheel-design-card.scss";
</style>

<script>
import IconSnow from '@/components/icons/IconSnow.vue';
import IconThreeSixty from '@/components/icons/IconThreeSixty.vue';
import { getImageBySize } from '@/utils';

export default {
  components: {
    IconSnow,
    IconThreeSixty,
  },
  props: {
    wheel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isWheelThreesixty() {
      return Array.isArray(this.wheel.showroomAngles) && this.wheel.showroomAngles.length > 24 && this.wheel.showroomUrlTemplate;
    },
  },
  methods: {
    onClickWheelDesign() {
      this.$emit('on-click-wheel-design');
    },
    getWheelImage(image) {
      return getImageBySize(image, 250, 250);
    },
  },
};
</script>