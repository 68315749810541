export const breadcrumbItems = [
  {
    key: 'brand',
    label: 'langkey.breadcrumb-brand',
    to: 'brand',
  },
  {
    key: 'wheelsDesign',
    label: 'langkey.breadcrumb-wheels-design',
  },
];

export const propertiesFilterFields = [
  {
    key: 'winter',
    label: 'langkey.configurator-prop-winterproof',
  },
];

export const selectFilterFields = [
  {
    key: 'design',
    placeholder: 'langkey.configurator-prop-design',
    singleSelectLabel: 'langkey.design',
    pluralSelectLabel: 'langkey.designs',
  },
  {
    key: 'diameter',
    placeholder: 'langkey.configurator-prop-diameter',
    singleSelectLabel: 'langkey.diameter',
    pluralSelectLabel: 'langkey.diameters',
  },
  {
    key: 'width',
    placeholder: 'langkey.configurator-prop-width',
    singleSelectLabel: 'langkey.width',
    pluralSelectLabel: 'langkey.widths',
  },
  {
    key: 'color',
    placeholder: 'langkey.configurator-prop-color',
    singleSelectLabel: 'langkey.color',
    pluralSelectLabel: 'langkey.colors',
  },
  {
    key: 'boltCircle',
    placeholder: 'langkey.configurator-prop-bolt-circle',
    singleSelectLabel: 'langkey.bolt-circle',
    pluralSelectLabel: 'langkey.bolt-circles',
  },
  {
    key: 'centerbore',
    placeholder: 'langkey.configurator-prop-centerbore',
    singleSelectLabel: 'langkey.centerbore',
    pluralSelectLabel: 'langkey.centerbores',
  },
  {
    key: 'offset',
    placeholder: 'langkey.configurator-prop-offset',
    singleSelectLabel: 'langkey.offset',
    pluralSelectLabel: 'langkey.offsets',
  },
];